
export class LayoutTranslationConfig {
  public layoutTranslations = {
    'en': {
      help: 'Help',
      mom: 'MOM',
      mtd: 'MTD',
      yoy: 'YOY',
      language: 'Language',
      logout: 'Log Out',
      support: 'Support',
      close: 'Close',
      english: 'English',
      french: 'French',
      apply: 'Apply',
      website: 'Website',
      // report navigation
      overview: 'Overview',
      scorecard: 'Scorecard',
      summary: 'Summary',
      referrer: 'Referrer',
      vehicle: 'Vehicle',
      supplier: 'Supplier',
      supplierscorecard: 'Supplier Scorecard',
      websitesupplierscorecard: 'Website Supplier Scorecard',
      management: 'Management',
      content: 'Content',
      users: 'Users',
      // report titles
      websiteoverview: 'Website Overview',
      websitesummary: 'Website Summary',
      referrersummary: 'Referrer Report',
      referrerreport: 'Referrer Report',
      vehiclesummary: 'Vehicle Summary',
      websiteproviderperformance: 'Website Provider Performance',
      websitesupplierperformance: 'Website Supplier Performance',
      providersummary: 'Website Supplier Performance',
      providerscorecard: 'Website Supplier Scorecard',
      yourlanguageselectionwillbeappliedthroughouttheapplication: 'Votre sélection de langue sera appliquée tout au long de l\'application.'
    },
    'fr-CA' : {
      help: 'Aide',
      mom: 'MSM',
      mtd: 'MÀJ',
      yoy: 'ASA',
      language: 'La Langue',
      logout: 'Se Déconnecter',
      support: 'Soutien',
      close: 'Fermer',
      english: 'Anglaise',
      french: 'Français',
      apply: 'Appliquer',
      website: 'Web',
      // report navigation
      overview: 'Aperçu',
      scorecard: 'Tableau de bord',
      summary: 'Sommaire',
      referrer: 'Référent',
      vehicle: 'Véhicule',
      supplier: 'Fournisseur',
      supplierscorecard: 'Tableau de bord du fournisseur',
      management: 'Utilisateurs',
      content: 'Content',
      users: 'Utilisateurs',
      digitalretailing: 'Vente au détail numérique',
      providertools: 'Outils pour les fournisseurs',
      // report titles
      websiteoverview: 'Aperçu du site Web',
      websitescorecard: 'Tableau de bord',
      websitesummary: 'Sommaire de site Web',
      referrersummary: 'Référent',
      referrerreport: 'Référent',
      vehiclesummary: 'Résumé du véhicule',
      suppliersummary: 'Performance du fournisseur de site Web',
      websiteproviderperformance: 'Performance du fournisseur de site Web',
      websitesupplierperformance: 'Performance du fournisseur de site Web',
      websitesuppliersummary: 'Performance du fournisseur de site Web',
      websitesupplierscorecard:  'Tableau de bord du fournisseur',
      providerscorecardreport: 'Tableau de bord du fournisseur',
      usermanagement: 'Gestion des utilisateurs',
      digitalretailingoverview: 'Sommaire de la Vente au détail Numerique',
      yourlanguageselectionwillbeappliedthroughouttheapplication: 'Votre sélection de langue sera appliquée tout au long de l\'application.',
      providerkpiexcel: 'KPI.s  Fournisseur  sur Excel',
      reportviews: 'Report Views',
      queries: 'Queries',
      datasets: 'Data Sets',
    }
  };

  public navLabelTranslations = {
    'en': {
      website: 'Website',
      overview: 'Overview',
      scorecard: 'Scorecard',
      summary: 'Summary',
      referrer: 'Referrer',
      vehicle: 'Vehicle',
      supplier: 'Supplier',
      supplierscorecard: 'Supplier Scorecard',
      management: 'Management',
      content: 'Content',
      users: 'Users',
    },
    'fr-CA': {
      website: 'Web',
      overview: 'Aperçu',
      scorecard: 'Tableau de bord',
      summary: 'Sommaire',
      referrer: 'Référent',
      vehicle: 'Véhicule',
      supplier: 'Fournisseur',
      supplierscorecard: 'Tableau de bord du fournisseur',
      management: 'Utilisateurs',
      content: 'Content',
      users: 'Utilisateurs'
    }

  };
}

