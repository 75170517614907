import { IDateRangeValue, ISelectedOperatorInfo, IHierarchyValue, IFacetedFilterSelection, ISelectedFacetInfo, ISelectedDateRangeInfo } from "../types/faceted-filter.types";
import { FilterUtils } from "./filter.utils";

export class DisplayValue {
    public static FromPath(path: string): string {
        return path.replace(/\|/g, ' > ');
    }

    public static FromStringArray(values: string[], path: string): string {
        const valueDisplay: string = values.length > 1 ? `${values[0]} + ${values.length - 1} more`
            : values[0];

        return `${this.formatPathDisplay(path)}: ${valueDisplay}`;
    }

    public static FromFacetSelection(filterSelection: IFacetedFilterSelection, selected: ISelectedFacetInfo) {
        const firstLabel = selected.facets[0].label || selected.facets[0].name;
        const valueDisplay = selected.facets.length > 1 ? `${firstLabel} + ${selected.facets.length - 1} more` : firstLabel;

        return `${filterSelection.name}: ${valueDisplay}`;
    }

    public static FromBooleanStringArray(values: string[], path): string {
        return `${this.formatPathDisplay(path, true)}: ${values[0]}`;
    }

    public static FromDateRange(value: IDateRangeValue, path: string, showDateRange: boolean = true): string {
        if (!showDateRange) {
            return FilterUtils.pathToDisplayPathsList(path).join(': ');
        }

        //take a string that looks like '2023-12-01T00:00:00.000Z' and return '12/1/2023'
        const startDateDisplay = this.AdjustDateForLocale(value.startDate);
        const endDateDisplay = this.AdjustDateForLocale(value.endDate);

        const valueDisplay = `${startDateDisplay} - ${endDateDisplay}`;

        return `${this.formatPathDisplay(path, false)}: ${valueDisplay}`;
    }

    public static AdjustDateForLocale(dateString: string): string {
        if (!dateString) return '';

        // Lock in the date format - eventually we will let the browser handle this
        const formatDateString = (date: Date) => {
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        }

        return formatDateString(new Date(dateString.split('T')[0] + 'T00:00:00.000'));
    }

    public static FromDateRangeSelection(filterSelection: IFacetedFilterSelection, selected: ISelectedDateRangeInfo) {
        //take a string that looks like '2023-12-01T00:00:00.000Z' and return '12/1/2023'
        const startDateString = this.AdjustDateForLocale(selected.dateRange.startDate);
        const endDateString = this.AdjustDateForLocale(selected.dateRange.endDate);
        const valueDisplay = `${startDateString} - ${endDateString}`;

        return `${selected.dateRange.name ?? "Custom"}: ${valueDisplay}`;
    }

    public static FromOperatorValue(value: ISelectedOperatorInfo, path: string): string {
        const valueDisplay = `${value.operatorInfo.label} ${value.operand}`;

        return `${this.formatPathDisplay(path)} ${valueDisplay}`;
    }

    public static FromHierarchies(values: IHierarchyValue[], path: string, valuesAreFacets: boolean): string {
        // Based on feedback, we want to preserve the first path (Dealers). TODO: need to verify
        const pathRoot = path.split('|')[0] + ' > ';
        let valueDisplay: string;
        if (!valuesAreFacets) {
            valueDisplay = values.map(v => v.value).join(', ');
        } else {
            valueDisplay = values.length > 1 ? `${values[0].value} + ${values.length - 1} more`
                : values[0].value;
        }
        const omitLastNode = valuesAreFacets && values.length === 1;
        const displayPaths: string = this.formatPathDisplay(path, omitLastNode);
        // return `${pathRoot}${displayPaths}${displayPaths.length ? ':' : ''} ${valueDisplay}`;
        return valueDisplay;
    }

    private static formatPathDisplay(path: string, omitLastNode: boolean = false): string {
        const pathsList = FilterUtils.pathToDisplayPathsList(path);

        if (omitLastNode)
            pathsList.pop();

        return pathsList.join(' > ');
    }
}
